import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export const query = graphql`
  query($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      id
      name
      slug
      date(formatString: "DD MMM YYYY", locale: "fr")
      body {
        raw
      }
    }
  }
`
const Page = props => {
  return (
    <Layout>
      <SEO title={props.data.page.name} />
      <div className="post-date">{props.data.page.date}</div>
      <div className="post-title medium">{props.data.page.name}</div>
      <div className="page-body">
        {documentToReactComponents(JSON.parse(props.data.page.body.raw))}
      </div>
    </Layout>
  )
}

export default Page
